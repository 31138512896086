import _ from 'lodash';
import moment from 'moment';

export const convertTimeBetISO = (
  input: string | number,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  formatString = 'ddd, MMM DD, h:mm A',
) => {
  return moment(input).format(formatString);
};

// eslint-disable-next-line lingui/no-unlocalized-strings
export const convertTime = (value: string, formatString = 'MMM DD, yyyy') => {
  return moment(value).format(formatString);
};

const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (_value?: any) => {
  const value = moment(_value);
  if (!value.isValid()) return _value;

  const dateTime = value.toDate();
  const utcFromLocal = new Date(
    dateTime.getTime() + getTimezoneOffset(dateTime),
  );
  return utcFromLocal;
};

export const localToUTC = (_value?: any) => {
  const value = moment(_value);
  if (!value.isValid()) return _value;

  const dateTime = value.toDate();

  const utcFromLocal = new Date(
    dateTime.getTime() - getTimezoneOffset(dateTime),
  );
  return utcFromLocal;
};

const mergeDateWithTime = (date: any, time: any) => {
  const _date = moment(date).utc(false);
  const _time = moment(time).utc(false);

  const a = _.chain(_date.toISOString()).split('T').first().value();
  const b = _.chain(_time.toISOString()).split('T').last().value();

  if (!a || !b) {
    return _date;
  }

  return moment(`${a}T${b}`);
};
